import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#FFFFFF',
    },
    footerItems: {
        ['& > *']: {
            display: 'block',
            padding: theme.spacing(1)
        }
    },
    link: {
        textDecoration: "none",
        color: theme.palette.text.primary,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    whatsapp: {
        zIndex: 4,
        position: 'fixed',
        bottom: '4%',
        right: '3%',
    }
}));

export { useStyles }