import React from "react"
import {
  useMediaQuery,
  useTheme,
  Box,
  Container,
  Grid,
  Typography,
} from "@material-ui/core"
import { Link } from "gatsby"
import Logo from "../../Logo"
import { useStyles } from "./style"

const Footer = ({ isLight }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isOverMobile = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <Box padding="1rem 0 1rem 0" className={classes.root}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item md={6} sm={12} xs={12}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems={isOverMobile ? "flex-start" : "center"}
              height="100%"
            >
              <Typography variant="caption">
                ©️ Bursery {new Date().getFullYear()}. All right reserved.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box
              cdisplay="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems={isOverMobile ? "flex-start" : "center"}
              textAlign={isOverMobile ? "right" : "center"}
            >
              <Typography style={{ marginRight: '15px' }} variant="caption">Privacy</Typography>
              <Typography style={{ marginRight: '15px' }} variant="caption">Terms of Service</Typography>

            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Footer
