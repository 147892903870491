import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        backgroundColor: props => props.transparentHeader ? 'transparent' : '#FFFFFF',
        padding: "1rem 0",
    },
    toolBar: {
        padding: theme.spacing(2, 0),
        alignItems: "center"
    },
    title: {
        flexGrow: 1,
        alignItems: "flex-start"
    },
    logo: {
        height: '6rem'
    },
    menuItem: {
        margin: theme.spacing(0,)
    },
    menuLink: {
        textDecoration: 'none',
        margin: theme.spacing(0,1),
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.primary.main
        },
    },
    menuButton: {
        textDecoration: 'none',
        margin: theme.spacing(0,0,0,1)
    },
    lightText: {
        margin: theme.spacing(0,0,0,4),
        color: theme.palette.background.default,
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    darkText: {
        margin: theme.spacing(0,0,0,4),
        color: theme.palette.text.primary,
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    initialsWrapper: {
        width: "2.7rem",
        height: "2.7rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        border: `2px solid ${theme.palette.primary.main}`,
      },
      hiddenNavs: {
          display: "flex",
          alignItems: "center",
      }

}))

export { useStyles }