import React from "react"
import { Typography } from "@material-ui/core";
// import Button from "../../Button";
import CookieConsent from "react-cookie-consent"
import { useStyles } from "./style"

const Cookie = () => {
  const classes = useStyles()
  return (
    <>
      <CookieConsent
        expires={365}
        sameSite="strict"
        location="bottom"
        buttonText="Accept"
        // declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        containerClasses={classes.container}
        // enableDeclineButton
        declineButtonStyle={{backgroundColor: "inherit", color: "#117AF3", fontWeight: "bold", fontSize: "1rem" }}
        buttonClasses={classes.button}
        buttonStyle={{
          backgroundColor: "rgb(17, 122, 243)",
          borderRadius: "4px",
          color: "#ffffff",
          fontSize: "14px",
          fontWeight: "bold",
          padding: ".8rem 1rem",
        }}
      >
        <Typography gutterBottom style={{fontWeight: "bold", fontSize: "16px"}}>This website uses cookies</Typography>
        <Typography style={{fontSize:"14px"}}> These cookies are used to collect information about how you interact
            with our website and allow us to remember you. We use this
            information in order to improve and customize your browsing
            experience and for analytics and metrics. For information about our
            cookies, see our{" "}
            <a
              href="https://edutech.global/cookie-policy"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#000000", textDecoration: "underline" }}
            >
              Cookie policy
            </a>
            . Read about our Privacy policy{" "}
            <a
              href="https://edutech.global/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#000000", textDecoration: "underline" }}
            >
              here.
            </a></Typography>
        
      </CookieConsent>
    </>
  )
}

export default Cookie
