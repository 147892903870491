import React, { useContext } from "react"
import PropTypes from "prop-types"
import { useStyles } from "./style"
import { Drawer, Button } from "@material-ui/core"
import { Link } from "gatsby"
import AuthContext from "../../../context/auth/authContext"

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props
  const classes = useStyles(props)
  const { isAuthenticated, user, loading } = useContext(AuthContext)

  let initials
  if (user !== null && user.firstName && user.lastName) {
    const { firstName, lastName } = user
    initials = firstName.charAt(0) + lastName.charAt(0)
  }

  const link = isAuthenticated ? "/dashboard" : "/user/register"
  const text = isAuthenticated ? initials : "Sign In"
  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={open}
      variant={variant}
      classes={{ paper: classes.drawer }}
    >
      <div {...rest} className={classes.root}>
        {/* <Button component={Link} to="/eligibility-check">
                    Check Eligibility
                </Button> */}
        <Button component={Link} to="/scholarships">
          Scholarships
        </Button>
        <Button component={Link} to="/about-us">
          About Us
        </Button>
        <Button component={Link} to="/partner">
          Partners
        </Button>
        <Button component={Link} to="/blog">
          Blog
        </Button>
        <Button component={Link} to="/refer">
          Refer
        </Button>
        <Button component={Link} to="/contact">
          Contact Us
        </Button>

        {!loading && (
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={link}
          >
            {text}
          </Button>
        )}
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
}

export default Sidebar
