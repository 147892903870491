import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import {
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Typography,
  useScrollTrigger,
  Slide,
  Box,
  Container,
} from "@material-ui/core"
import { useStyles } from "./style"
import MenuIcon from "@material-ui/icons/Menu"
import Button from "../../Button"
import Logo from "../../Logo"
import UserInitials from "../UserInitials"
import AuthContext from "../../../context/auth/authContext"

function HideOnScroll(props) {
  const { children } = props

  const trigger = useScrollTrigger()

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const Header = props => {
  const { className, onSidebarOpen, isLight, ...rest } = props
  const classes = useStyles(props)
  const { isAuthenticated, loading } = useContext(AuthContext)
  const link = isAuthenticated ? "/dashboard" : "/user/login"

  return (
    <>
      {/* <ParentLayout> */}
      <HideOnScroll {...props}>
        <AppBar className={classes.root} {...rest}>
          <Container maxWidth="lg">
            <Toolbar disableGutters className={classes.toolBar}>
              <div className={classes.title}>
                <Box width="10rem">
                  <Link to="/">
                    <Logo logoDark={isLight ? false : true} />
                  </Link>
                </Box>
              </div>

              <Hidden smDown>
                <Box className={classes.hiddenNavs}>
                  {/* <Typography
                    variant="body1"
                    component={Link}
                    to="/eligibility-check"
                    className={isLight ? classes.lightText : classes.darkText}
                  >
                    Eligibility Check
                  </Typography> */}
                  <Typography
                    variant="body1"
                    component={Link}
                    to="/scholarships"
                    className={isLight ? classes.lightText : classes.darkText}
                  >
                    Scholarships
                  </Typography>
                  <Typography
                    variant="body1"
                    component={Link}
                    to="/about-us"
                    className={isLight ? classes.lightText : classes.darkText}
                  >
                    About Us
                  </Typography>
                  <Typography
                    variant="body1"
                    component={Link}
                    to="/partner"
                    className={isLight ? classes.lightText : classes.darkText}
                  >
                    Partners
                  </Typography>

                  <Typography
                    variant="body1"
                    component={Link}
                    to="/blog"
                    className={isLight ? classes.lightText : classes.darkText}
                  >
                    Blog
                  </Typography>

                  {/* <Typography
                  variant="body1"
                  component={Link}
                  to="/faq"
                  className={isLight ? classes.lightText : classes.darkText}
                >
                  FAQ
                </Typography> */}

                  <Typography
                    variant="body1"
                    component={Link}
                    to="/refer"
                    className={isLight ? classes.lightText : classes.darkText}
                  >
                    Refer
                  </Typography>

                  <Typography
                    variant="body1"
                    component={Link}
                    to="/contact"
                    className={isLight ? classes.lightText : classes.darkText}
                  >
                    Contact Us
                  </Typography>
                  <Box marginLeft="2rem">
                    {loading ? (
                      ""
                    ) : isAuthenticated ? (
                      <Link
                        to={link}
                        style={{ textDecoration: "none", color: "#FFFFFF" }}
                      >
                        <Box className={classes.initialsWrapper}>
                          <UserInitials />
                        </Box>
                      </Link>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={link}
                      >
                        Sign In
                      </Button>
                    )}
                  </Box>
                </Box>
              </Hidden>
              <Hidden mdUp>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={onSidebarOpen}
                >
                  <MenuIcon color="primary" />
                </IconButton>
              </Hidden>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      {/* </ParentLayout> */}
    </>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  isLight: PropTypes.bool,
}

export default Header
