import React, { useState } from 'react';
import PropTypes from 'prop-types'
import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'
import ParentLayout from '../ParentLayout'

import Cookie from '../Cookie';



const Layout = props => {
    const { children, withFooter, withHeader, isLight } = props

    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };


    return (
        <>
            <ParentLayout>
                {withHeader && (
                    <>
                        <Header
                            onSidebarOpen={handleSidebarOpen}
                            isLight={isLight}
                        // transparentHeader={transparentHeader}
                        />
                    </>
                )}
                <main>
                    {/* <Container maxWidth="xl"> */}
                    {children}
                    {/* </Container> */}
                </main>

                {withFooter && (
                    <>
                        <Footer isLight={isLight} />
                    </>
                )}
                <Sidebar
                    onClose={handleSidebarClose}
                    open={openSidebar}
                    variant="temporary"
                />
                <Cookie />
                {/* <Alert {...props} /> */}
                {/* <Feedback /> */}
            </ParentLayout>
        </>

    )



}

Layout.propTypes = {
    withFooter: PropTypes.bool,
    isLight: PropTypes.bool
}

export default Layout
