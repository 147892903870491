import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  container: {
    padding: ".5rem 5rem",
    color: "black!important",
    backgroundColor: "#FFFFFF!important",
    display: "flex",
    alignItems: "center!important",
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
  button: {
    transition: "all .7s cubic-bezier(.2,1,.22,1)",
    "&:hover": {
      backgroundColor: "#0A1731",
      cursor: "pointer",
      boxShadow: "0px 10px 20px rgba(17, 122, 243, 0.3)",
      transform: "translateY(-3px)",
    },
  },
}))

export { useStyles }
